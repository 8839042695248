import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { format, parseISO } from "date-fns";
import { useArticleDetail } from "./queries/use-article-detail";

export default function ArticleDetail() {
  const { slug } = useParams();
  const article = useArticleDetail(slug);
  const formatDate = (date) => format(parseISO(date), "MMMM d yyyy")

  return (
    <>
      <div className="wrapper-pages">
        <Navbar />
        <div className="article-details-wrapper">
          <div className="images">
            <img src="/images/banner-article-details.png" alt="" />
          </div>

          {article.isSuccess && (
            <div className="container">
              <div className="desc-wrapper">
                <div className="heading">
                  <h5>{article.data?.categories_text ?? "-"}</h5>
                  <h1>{article.data?.title?.rendered ?? "-"}</h1>
                  <h5>Posted on {formatDate(article.data?.date)}, by Admin</h5>
                </div>

                <div
                  className="desc-body"
                  dangerouslySetInnerHTML={{
                    __html: article.data?.content?.rendered ?? "-",
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}
