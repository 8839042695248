import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Api from '../../../../../endpoint/api';

export function useArticleDetail(slug) {
    const getArticleDetailFn = async () => {
        const postResponse = await axios.get(`${Api.wp_url}/wp-json/wp/v2/posts?slug=${slug}`);

        return postResponse.data[0];
    };

    return useQuery({
        queryKey: ['useArticleDetail', slug],
        queryFn: getArticleDetailFn,
        keepPreviousData: true,
        enabled: !!slug,
        refetchOnMount: false,
        staleTime: 1000 * 60 * 60 * 24,
    });
}
